<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">{{account.name}}</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h4 class="title">Editar Perfil</h4>
            </div>
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="col-md-5">
                    <base-input
                      label="Usuário"
                      type="text"
                      :disabled="true"
                      v-model="username"/>
                  </div>
                  <div class="col-md-7">
                    <base-input
                      label="E-mail"
                      type="text"
                      :disabled="true"
                      v-model="account.email"/>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <base-input
                      label="Celular"
                      type="tel"
                      v-mask="['(##) #####-####']"
                      :disabled="formDisabled"
                      v-model="account.phoneNumber1"/>
                  </div>
                  <div class="col-md-6">
                    <base-input
                      label="Telefone"
                      type="tel"
                      v-mask="['(##) ####-####']"
                      :disabled="formDisabled"
                      v-model="account.phoneNumber2"/>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <base-input
                      label="Nome Completo"
                      type="text"
                      :disabled="formDisabled"
                      v-model="account.name"/>
                  </div>
                </div>
                <div class="row" style="margin-top: 30px">
                  <div class="col-md-5">
                    <base-input label="Tipo de Pessoa">
                      <el-select size="large"
                                 placeholder="Selecionar"
                                 v-model="account.personType"
                                 ::disabled="formDisabled">
                        <el-option class="select-success"
                                   value="NATURAL_PERSON"
                                   label="Pessoa Física"/>
                        <el-option class="select-info"
                                   value="LEGAL_PERSON"
                                   label="Pessoa Jurídica"/>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-md-7">
                    <base-input :label="'CPF'"
                                type="tel"
                                v-mask="['###.###.###-##']"
                                name="CPF/CNPJ"
                                :rules="{required: true, min: 14}"
                                v-model="account.socialNumber"
                                key="social-number-cpf"
                                :disabled="formDisabled"
                                v-if="account.personType=='NATURAL_PERSON'">
                    </base-input>
                    <base-input :label="'CNPJ'"
                                type="tel"
                                v-mask="['##.###.###/####-##']"
                                name="CNPJ/CNPJ"
                                :rules="{required: true, min: 14}"
                                v-model="account.socialNumber"
                                key="social-number-cnpj"
                                :disabled="formDisabled"
                                v-if="account.personType!='NATURAL_PERSON'">
                    </base-input>
                  </div>
                </div>
                <div v-if="account.personType=='LEGAL_PERSON'">
                  <div class="row">
                    <div class="col-md-12">
                      <base-input type="text"
                                  label="Razão Social"
                                  maxlength="100"
                                  name="Razão Social"
                                  :rules="{required: true}"
                                  v-model="account.legalPerson.companyName"
                                  ::disabled="formDisabled">
                      </base-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-7">
                      <base-input type="text"
                                  maxlength="255"
                                  label="Atividade Principal"
                                  name="Atividade Principal"
                                  :rules="{required: true}"
                                  v-model="account.legalPerson.mainActivity"
                                  ::disabled="formDisabled">
                      </base-input>
                    </div>
                    <div class="col-md-5">
                      <div class="row" style="padding-left: 15px">
                        <base-input type="tel"
                                    v-mask="['##/##/####']"
                                    label="Data de Constituição"
                                    name="Data de Constituição"
                                    :rules="{required: true, min:10}"
                                    v-model="account.legalPerson.openingDate"
                                    ::disabled="formDisabled"></base-input>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="account.personType=='NATURAL_PERSON'">
                  <div class="row">
                    <div class="col-md-7">
                      <base-input type="text"
                                  maxlength="40"
                                  label="Naturalidade"
                                  name="Naturalidade"
                                  :rules="{required: true}"
                                  v-model="account.naturalPerson.birthplace"
                                  ::disabled="formDisabled">
                      </base-input>
                    </div>
                    <div class="col-md-5">
                      <div class="row" style="padding-left: 15px">
                        <base-input type="tel"
                                    v-mask="['##/##/####']"
                                    label="Data de Nascimento"
                                    name="Data de Nascimento"
                                    :rules="{required: true, min:10}"
                                    v-model="account.naturalPerson.birthDate"
                                    ::disabled="formDisabled"></base-input>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4"
                         :style="account.naturalPerson.identificationDocument.documentType!='RG'?'margin-bottom: 10px':''">
                      <base-input label="Tipo de Documento">
                        <el-select size="large"
                                   placeholder="Selecionar"
                                   v-model="account.naturalPerson.identificationDocument.documentType"
                                   ::disabled="formDisabled">
                          <el-option class="select-success"
                                     value="RG"
                                     label="RG"/>
                          <el-option class="select-success"
                                     value="CNH"
                                     label="CNH"/>
                          <el-option class="select-success"
                                     value="PASSPORT"
                                     label="Passaporte"/>
                        </el-select>
                      </base-input>
                    </div>
                    <div class="col-md-8" v-if="account.naturalPerson.identificationDocument.documentType=='RG'">
                      <base-input type="text"
                                  maxlength="40"
                                  label="Orgão Expedidor"
                                  name="Orgão Expedidor"
                                  :rules="{required: true}"
                                  v-model="account.naturalPerson.identificationDocument.dispatchingAgency"
                                  ::disabled="formDisabled">
                      </base-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <base-input type="text"
                                  maxlength="20"
                                  label="Número do Documento"
                                  name="Número do Documento"
                                  :rules="{required: true}"
                                  v-model="account.naturalPerson.identificationDocument.number"
                                  ::disabled="formDisabled">
                      </base-input>
                    </div>
                    <div class="col-md-6">
                      <div class="row" style="padding-left: 15px">
                        <base-input type="tel"
                                    v-mask="['##/##/####']"
                                    label="Data de Expedição"
                                    name="Data de Expedição"
                                    :rules="{required: true, min:10}"
                                    v-model="account.naturalPerson.identificationDocument.emissionDate"
                                    ::disabled="formDisabled"></base-input>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-bottom: 10px">
                    <div class="col-md-4">
                      <base-input label="Sexo">
                        <el-select size="large"
                                   placeholder="Selecionar"
                                   v-model="account.naturalPerson.gender"
                                   ::disabled="formDisabled">
                          <el-option class="select-success"
                                     value="MALE"
                                     label="Masculino"/>
                          <el-option class="select-info"
                                     value="FEMALE"
                                     label="Feminino"/>
                        </el-select>
                      </base-input>
                    </div>
                    <div class="col-md-8">
                      <base-input label="Estado Civil">
                        <el-select size="large"
                                   placeholder="Selecionar"
                                   v-model="account.naturalPerson.maritalStatus"
                                   ::disabled="formDisabled">
                          <el-option class="select-info"
                                     value="SINGLE"
                                     label="Solteiro"/>
                          <el-option class="select-danger"
                                     value="MARRIED"
                                     label="Casado"/>
                          <el-option class="select-success"
                                     value="DIVORCED"
                                     label="Divorciado"/>
                          <el-option class="select-warning"
                                     value="WIDOWED"
                                     label="Viúvo"/>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                  <div class="row" v-if="account.naturalPerson.maritalStatus=='MARRIED'">
                    <div class="col-md-12">
                      <base-input type="text"
                                  maxlength="70"
                                  label="Nome do Cônjuge"
                                  v-model="account.naturalPerson.partnerName"
                                  ::disabled="formDisabled">
                      </base-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <base-input type="text"
                                  maxlength="30"
                                  label="Profissão"
                                  name="Profissão"
                                  :rules="{required: true}"
                                  v-model="account.naturalPerson.profession"
                                  ::disabled="formDisabled">
                      </base-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <base-input type="text"
                                  maxlength="70"
                                  label="Nome da Mãe"
                                  name="Nome da Mãe"
                                  :rules="{required: true}"
                                  v-model="account.naturalPerson.motherName"
                                  ::disabled="formDisabled">
                      </base-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <base-input type="text"
                                  maxlength="70"
                                  label="Nome do Pai"
                                  v-model="account.naturalPerson.fatherName"
                                  ::disabled="formDisabled">
                      </base-input>
                    </div>
                  </div>
                </div>

                <div class="row" style="margin-top: 30px">
                  <div class="col-md-5">
                    <base-input type="tel"
                                v-mask="['#####-###']"
                                label="CEP"
                                name="CEP"
                                :rules="{required: true, min: 9}"
                                v-model="account.address.zipCode"
                                ::disabled="formDisabled">
                    </base-input>
                  </div>
                  <div class="col-md-7">
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <base-input type="text"
                                maxlength="40"
                                ::disabled="(viacep && viacep.logradouro) || formDisabled"
                                label="Rua/Av."
                                name="Rua/Av."
                                :rules="{required: true}"
                                v-model="account.address.street">
                    </base-input>
                  </div>
                  <div class="col-md-4">
                    <base-input type="tel"
                                v-mask="['######']"
                                label="Número"
                                v-model="account.address.number"
                                ::disabled="formDisabled">
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <base-input type="text"
                                maxlength="40"
                                label="Complemento"
                                v-model="account.address.complement"
                                ::disabled="formDisabled">
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <base-input type="text"
                                maxlength="40"
                                ::disabled="(viacep && viacep.bairro) || formDisabled"
                                label="Bairro"
                                name="Bairro"
                                :rules="{required: true}"
                                v-model="account.address.neighborhood">
                    </base-input>
                  </div>
                  <div class="col-md-5">
                    <base-input type="text"
                                label="Cidade"
                                :disabled="true"
                                v-model="account.address.city">
                    </base-input>
                  </div>
                  <div class="col-md-2">
                    <base-input type="text"
                                label="Estado"
                                :disabled="true"
                                placeholder=""
                                v-model="account.address.state">
                    </base-input>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-12" v-for="image in images">
          <div class="card">
            <div class="card-body" style="margin: 0 auto">
              <div class="row">
                <div class="col-md-12 doc-image">
                  <a href="#" @click="openDoc(image)">
                    <embed width="900" height="900" :src='loadDocSrc(image)'
                           v-if="'pdf'==image.imageFileType.replace(' ', '').toLowerCase()"/>
                    <img :src="loadDocSrc(image)" v-else width="100%"/>
                  </a>

                  <base-input type="text" :disabled="true"
                              v-model="image.id"/>

                  <base-input type="text" :disabled="true"
                              v-model="image.documentName"/>

                  <div style="display:table; margin: 0 auto !important">
                    <base-input>
                      <el-select size="large" :disabled="true"
                                 placeholder="Selecionar"
                                 v-model="image.documentPhotoType">
                        <el-option value="PHOTO_DOCUMENT"
                                   label="Foto da Frente do Documento"/>
                        <el-option value="BACK_PHOTO_DOCUMENT"
                                   label="Foto do Verso do Documento"/>
                        <el-option value="PROOF_OF_RESIDENCE"
                                   label="Comprovante de Endereço"/>
                        <el-option value="SELFIE"
                                   label="Selfie"/>
                        <el-option value="CNPJ_CARD"
                                   label="Cartão CNPJ"/>
                        <el-option value="SOCIAL_CONTRACT"
                                   label="Contrato Social"/>
                        <el-option value="PROOF_OF_COMPANY_ADDRESS"
                                   label="Comprovante Endereço Juridico"/>
                      </el-select>
                    </base-input>
                    <base-input>
                      <el-select size="large"
                                 :class="image.documentPhotoStatus=='OK'?'select-success':image.documentPhotoStatus=='REJECTED'?'select-danger':'select-warning'"
                                 placeholder="Selecionar"
                                 v-model="image.documentPhotoStatus">
                        <el-option class="select-warning"
                                   value="WAITING_APPROVAL"
                                   label="AGUARDANDO APROVAÇÃO"/>
                        <el-option class="select-danger"
                                   value="REJECTED"
                                   label="REJEITADO"/>
                        <el-option class="select-success"
                                   value="OK"
                                   label="APROVADO"/>
                      </el-select>
                    </base-input>
                    <base-input>
                      <el-select size="large"
                                 :class="image.causeError==null?'select-success':'select-danger'"
                                 placeholder="Selecionar"
                                 v-model="image.causeError">
                        <el-option class="select-success"
                                   :value="null"
                                   label="Nenhum"/>
                        <el-option class="select-danger"
                                   value="INVALID"
                                   label="Doc. Inválido"/>
                        <el-option class="select-danger"
                                   value="INCOMPATIBLE"
                                   label="Doc. Diverge do Form."/>
                        <el-option class="select-danger"
                                   value="UNREADABLE"
                                   label="Doc. Ilegível"/>
                        <el-option class="select-danger"
                                   value="EXPIRED_DOCUMENT"
                                   label="Doc. vencido"/>
                        <el-option class="select-danger"
                                   value="FRAUD_SUSPICION"
                                   label="Suspeita de Fraude"/>
                      </el-select>
                    </base-input>
                  </div>
                </div>
              </div>
              <div class="row">
                <button class="btn btn-info btn-fill btn-wd" style="margin: 20px auto"
                        @click="openDoc(image)">
                  <i class="fas fa-search-plus"></i>
                  VISUALIZAR DOCUMENTO EM NOVA JANELA
                </button>
              </div>
              <div class="row">
                <button class="btn btn-success btn-fill btn-wd" style="margin: 20px auto"
                        type="submit" @click="saveDoc(image)">
                  <i class="fas fa-save"></i>
                  Salvar Documento
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {failWs, getWs, postWs} from '../../../ws.service'
  import {isNullOrEmpty} from '../../../util/core.utils'
  import {Option, Select} from 'element-ui'

  export default {
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
        username: '',
        viacep: {
          logradouro: '',
          bairro: ''
        },
        formDisabled: false,
        account: {
          name: '',
          email: '',
          socialNumber: '',
          personType: 'NATURAL_PERSON',
          phoneNumber1: '',
          phoneNumber2: '',
          accountDocumentStatus: '',
          address: {
            codIbge: '',
            street: '',
            number: '',
            complement: '',
            zipCode: '',
            neighborhood: '',
            city: '',
            state: ''
          },
          legalPerson: {
            companyName: '',
            openingDate: '',
            mainActivity: ''
          },
          naturalPerson: {
            motherName: '',
            fatherName: '',
            birthDate: '',
            birthplace: '',
            gender: 'MALE',
            maritalStatus: 'SINGLE',
            partnerName: '',
            profession: '',
            identificationDocument: {
              documentType: 'RG',
              number: '',
              emissionDate: '',
              dispatchingAgency: ''
            }
          }
        },
        images: []
      }
    },
    created() {
      this.username = this.$route.params.username
      postWs("/acc/get-account", true,
        {username: this.username}, null,
        this.loadAccount, failWs)
      postWs("/document/status", true,
        {username: this.username}, null,
        this.loadDoc, failWs)
    },
    methods: {
      saveDoc(image) {
        if (image.documentPhotoStatus == 'REJECTED' && image.causeError == null) {
          this.$swal({
            title: 'Alerta!',
            text: 'Informe o motivo pelo qual o documento foi rejeitado.',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            icon: 'warning'
          })
          return
        }
        postWs("/bo/document/update-status", true, null,
          {id: image.id, status: image.documentPhotoStatus, cause: image.causeError},
          () => {
            this.$swal({
              title: 'Sucesso!',
              text: 'Status salvo com sucesso.',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-success btn-fill'
              },
              timer: 5000,
              icon: 'success'
            })
          }, this.errorWs)
      },
      errorWs() {
        this.$swal({
          title: 'Erro!',
          text: 'Tente novamente mais tarde!',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-danger btn-fill'
          },
          timer: 5000,
          icon: 'error'
        })
      },
      loadAccount(response) {
        this.account = response.data
        this.formDisabled = true
        if (!isNullOrEmpty(this.account.address.codIbge)) {
          this.viacep.logradouro = this.account.address.street
          this.viacep.bairro = this.account.address.neighborhood
        }
      },
      loadDoc(response) {
        this.images = []
        if (response.data.length > 0) {
          for (let i in response.data) {
            this.loadDocData(response.data[i])
          }
        }
      },
      loadDocData(image) {
        getWs("/bo/document/image", true,
          {id: image.id},
          (response) => {
            this.images.push(response.data)
          }, failWs)
      },
      loadDocSrc(image) {
        let fileType = image.imageFileType.replace(" ", "").toLowerCase()
        if (fileType == 'pdf') {
          return 'data:application/pdf;base64,' + image.imageBase64
        } else if (fileType == 'jpg') {
          return 'data:image/jpeg;base64,' + image.imageBase64
        }
        return 'data:image/' + fileType + ';base64,' + image.imageBase64
      },
      openDoc(image) {
        let fileType = image.imageFileType.replace(" ", "").toLowerCase()
        let imageDimension = " width='100%' "
        if (fileType == 'pdf') {
          imageDimension += " height='100%' "
        }
        try {
          let pdfWindow = window.open("about:blank");
          pdfWindow.document.write("<html<head><title>Olimpo Pay - Validação de Documento</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>");
          pdfWindow.document.write("<body><embed" + imageDimension + "src='" + this.loadDocSrc(image) + "'></embed></body></html>"
          )
        } catch (ex) {
          this.$swal({
            title: 'Aviso!',
            text: "Para a exibição do documento, favor permitir janela pop-up no navegador. Após permitido favor tentar novamente.",
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            icon: 'warning'
          })
        }
      },
      getError(fieldName) {
        console.log(fieldName)
      }
    }
  }

</script>
<style lang="scss">
  .dzs-acc-edit {
    .doc-image input {
      min-width: 400px;
      text-align: center;
      color: #000000 !important;
    }
  }
</style>
